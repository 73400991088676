<template>
  <v-container :id="text.slug" class="px-0 pt-8 pb-8 pt-md-16 pb-md-16">
    <v-row justify="center" class="pt-4">
      <v-col cols="12" md="8" xl="6" class="pb-0">
        <H2Number
          :number="text.title_has_number ? `${text.title_number}` : ''"
          :title="`${activeTranslation?.title}`"
          :class="{ 'text-center': mdAndUp }" />
      </v-col>

      <v-col cols="12" md="8" class="py-0">
        <div
          class="d-flex justify-center text-left custom-subtitle"
          :class="{ 'text-center': mdAndUp }">
          {{ activeTranslation?.subtitle }}
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="activeTranslation?.text_content && activeTranslation?.text_content !== null"
      justify="center">
      <v-col
        cols="12"
        md="8"
        class="d-flex justify-center text-left"
        :class="{ 'text-center': mdAndUp }">
        <div class="text-cms-content" v-html="sanitizeHtml(activeTranslation?.text_content)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'
import { useDisplay } from 'vuetify'
import type { Text, TextTranslation } from '~/types/types'
import { sanitizeHtml } from '~/util/sanitizer'

const { mdAndUp } = useDisplay()
const { locale, locales } = useI18n()

const props = defineProps({
  text: {
    type: Object as PropType<Text>,
    default: () => ({}),
  },
})

const { text } = toRefs(props)

const currentLocaleIso = computed(() => {
  return locales.value?.find((l: LocaleObject) => l.code === locale.value)?.language as string
})

const activeTranslation = computed(() => {
  return text.value.translations?.find(
    (t: TextTranslation) => t.languages_code === currentLocaleIso.value,
  )
})

if (
  activeTranslation.value?.text_content?.includes('<table') &&
  activeTranslation.value?.text_content?.includes('border="')
) {
  activeTranslation.value.text_content = activeTranslation.value?.text_content.replace(
    /border=".*?"/g,
    '',
  )
}

const tableSpanArray = ref<HTMLSpanElement[]>([])

function formatTable() {
  if (activeTranslation.value?.text_content?.includes('<table') && !mdAndUp) {
    const parser = new DOMParser()

    const doc: Document = parser.parseFromString(
      activeTranslation.value?.text_content ?? '',
      'text/html',
    )

    const spanElements: NodeListOf<HTMLSpanElement> = doc.querySelectorAll('table span')

    tableSpanArray.value = Array.from(spanElements)

    const tableElement = doc.querySelector('table')
    if (tableElement) {
      tableElement.remove()
    }

    activeTranslation.value.text_content = doc.body.innerHTML
  }
}

onMounted(() => {
  formatTable()
  window.addEventListener('resize', formatTable)
})

onUnmounted(() => {
  window.removeEventListener('resize', formatTable)
})
</script>
<style scoped>
:deep(table, tr, td, th) {
  border: none !important;
}
</style>
